$cardPosition: 0;
$cardOffset: 20px;



.card {
  width: 375px;
  height: 595px;
  background-color: #fff;
  box-shadow: 0 -2px 0 50px rgba(0,0,0,0.1);
  border-radius: 12px;
  display: inline-block;
  position: absolute;
  transform-origin: 0% 50%;
  transition: all 1s ease;
  background-size: contain;

  @media (max-width: 1200px) {
    width: 295px;
    height: 475px;
  }

  @media (max-width: 640px) {
    width: 245px;
    height: 375px;
  }

  @media (max-width: 375px) {
    width: 185px;
    height: 285px;
  }
}

.card-stack {
  display: inline-block;
  text-align: center;
  position: relative;

  @media (max-width: 480px) {
    transform: translateX(-50px);
  }
}

.card1 {
  z-index: 1000;
  left: $cardPosition;
}

.card2 {
  z-index: 800;
  transform: scale(0.99);
  filter: brightness(0.9);
  left: $cardPosition + $cardOffset;
}

.card3 {
  z-index: 600;
  transform: scale(0.98);
  filter: brightness(0.8);
  left: $cardPosition + $cardOffset*2;
}

.card4 {
  z-index: 400;
  transform: scale(0.97);
  filter: brightness(0.7);
  left: $cardPosition + $cardOffset*3;
  animation-name: remove;
  animation-duration: 1s;
}

.card5 {
  z-index: 200;
  transform: scale(0.96);
  filter: brightness(0.6);
  left: $cardPosition + $cardOffset*4;
  animation-name: remove;
  animation-duration: 1s;
}

@keyframes remove {
  0% {
    transform: translateX(0px);
    opacity: 1
  }
  50% {
    transform: translateX(-80px);
    opacity: 0
  }
  51% {
    transform: translateX(10px) scale(0.97);
    opacity: 0
  }
  100% {
    transform: translateX(0px) scale(0.97);
    opacity: 1
  }
}

