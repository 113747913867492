@import "../../../assets/styles/main";

$tabItemColor: $neutral600;

.feature-block {

  &__container {
    width: 100%;
    height: 50vh;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;

    @media (max-width: 640px) {
      width: 90%;
    }
  }

  &__content {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;

    @media (max-width: 640px) {
      flex-direction: column-reverse;
    }
  }

  &__feature-description {
    width: 50%;

    @media (max-width: 640px) {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 480px) {
      width: 80%;
    }

  }

  &__feature-highlight {
    transform: translateY(50px);
    position: absolute;
    max-width: 480px;

    @media (max-width: 1200px) {
      max-width: 295px;
    }

    @media (max-width: 640px) {
      max-width: 100%;
    }

    @media (max-width: 480px) {
      width: 80%;
    }
  }

  &__tab-container {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20rem;

    @media (max-width: 640px) {
      width: 100%;
      padding-top: 6rem;
    }

    @media (max-width: 480px) {
      padding-top: 18rem;
    }
  }
  &__feature-title {
    margin-bottom: 0;
  }

  &__tab-item {
    @extend %body;
    font-weight: 500;
    text-transform: capitalize;
    color: $tabItemColor;
    padding: .75rem 1.5rem;
    border-radius: 38px;
    border: 1px solid $tabItemColor;
    margin-right: 0.75rem;
  }



}



.pin-up {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
}



.content-wrap {
  position:relative;
  height: 100%;
  width: 50%;
  top: -6rem;

  @media (max-width: 640px) {
    width: 100%;
    top: 0;
  }

  @media (max-width: 480px) {
    height: 40%;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 4px;
  font-size: 30px;
  color: white;
  transform: translateY(50px);
  position: absolute;
}

.footer {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content:center;
  background-color: lightgrey;
}

.spacer {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content:center;
}

.spacer span {
  color: green;
  font-size: 30px;
}

.test-container {
  height: 100vh;
}
