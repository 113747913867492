@import "../../../assets/styles/main";

$backgroundColor: $stoneWhite;
$titleColor: $stoneBlack;

.text-block {

  &__container {
    display: flex;
    height: 100vh;
    align-items: center;
    background-color: $backgroundColor;
    width: 100%;
  }

  &__title {
    width: 100%;
      h1 {
        color: $titleColor;
        text-align: center;
      }
  }
}
