@import "../../../assets/styles/main";

$howWorkBackgroundColor: $stoneBlack;
$itemNumberColor: $stone300;
$activeItemNumberColor: $stone100;
$listItemActiveBackgroundColor: $stone600;

.how-work {
  &__container {
    min-height: 100vh;
    background-color: $stoneBlack;
  }

  &__title {
    text-align: center;
    padding-top: 11rem;
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 5rem;
    padding-bottom: 8rem;

    @media (max-width: 640px) {
      flex-direction: column;
    }

    @media (max-width: 640px) {
      padding-top: 1.5rem;
    }
  }

  &__preview {
    width: 50%;

    @media (max-width: 768px) {
      align-self: center;
      height: 395px;
    }

    @media (max-width: 375px) {
      height: 345px;
    }
  }

  &__feature-container {
    width: 50%;

    @media (max-width: 768px) {
      width: 45%;
    }

    @media (max-width: 640px) {
      width: 100%;
      padding-top: 3rem;
    }

    @media (max-width: 375px) {
      padding-top: 0;
    }
  }

  &__feature-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__feature-list-item {

    padding: 1.5rem;
    background-color: transparent;
    transition: background-color 0.3s ease;
    border-radius: 12px;
    cursor: pointer;
    min-height: 94px;
    margin-bottom: 1.5rem;

    &:hover {
      background-color: $listItemActiveBackgroundColor;
    }

    h4 {
      color: $itemNumberColor;
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s ease;
      opacity: 0;
    }

    &.active {

      background-color: $listItemActiveBackgroundColor;

      h4 {
        color: $activeItemNumberColor;
        max-height: 300px;
        opacity: 1;
      }

      .how-work__item-title {
        h3 {
          color: $activeItemNumberColor;
          transition: color 0.3s ease;
        }

        margin-bottom: 1.5rem;
      }

      .how-work__item-number {
        color: $activeItemNumberColor;
        border: 1px solid $activeItemNumberColor;
        transition: all 0.3s ease;
      }
    }
  }

  &__item-title {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    transition: all 0.3s ease;

    h3 {
      color: $itemNumberColor;
    }
  }

  &__item-number {

    padding: .75rem 1.5rem;
    color: $itemNumberColor;
    border: 1px solid $itemNumberColor;
    border-radius: 34px;
    margin-right: 1.5rem;

  }




}
