@import "../../../assets/styles/main";

$headerDefaultBackground: $neutral100;

.main-header {
  padding: 0.5rem 6rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  backdrop-filter: none;
  align-items: center;
  z-index: 10000;

  @media (max-width: 768px) {
    padding: 0.5rem 2rem;
  }

  @media (max-width: 540px) {
    a {
      display: none;
    }
  }

  &.hide {
    display: none;
  }

  &.background {
    background-color: rgba($headerDefaultBackground, 0.85);
    backdrop-filter: blur(30px);

    @media (max-width: 540px) {
      background-color: transparent;
      backdrop-filter: blur(0);
    }
  }

  &.background-alt {
    background-color: rgba($headerDefaultBackground, 0.85);
    backdrop-filter: blur(30px);
  }

  &.stone-white {
    background-color: rgba($stoneWhite, 0.85);
    backdrop-filter: blur(30px);
  }

  &.cool-100 {
    background-color: rgba($cool100, 0.85);
    backdrop-filter: blur(30px);

    .main-header__logo {
      background-image: url('../../../assets/images/logo-cool.svg');
    }

    a {
      color: $cool500;
    }

    .btn--stone {
      @extend .btn--cool;
    }
  }

  &.stone-black {
    background-color: rgba($stoneBlack, 0.85);
    backdrop-filter: blur(30px);

    a {
      color: $stoneWhite;
    }

    .btn--stone {
      @extend .btn--stone-white;
    }

    .main-header__logo {
      background-image: url('../../../assets/images/logo-white.svg');
    }

  }

  &__navigation {
    list-style: none;
    display: flex;
    align-items: center;

    @media (max-width: 540px) {
      padding: 0;
      margin: 0;
    }
  }

  &__nav-item {
    margin-right: 1.5rem;
    cursor: pointer;

    @media (max-width: 540px) {
      margin-right: 0;
    }
  }

  &__logo {
    width: 203px;
    height: 48px;
    background-image: url('../../../assets/images/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 540px) {
      width: 123px;
      height: 30px;
    }

    @media (max-width: 480px) {
      width: 123px;
      height: 30px;
    }
  }
}
