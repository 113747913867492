@font-face {
  font-family: 'Basel Grotesk';
  src: url('../../fonts/BaselGrotesk-Regular.woff2') format('woff2'),
  url('../../fonts/BaselGrotesk-Regular.woff') format('woff'),
  url('../../fonts/BaselGrotesk-Regular.ttf') format('truetype'),
  url('../../fonts/BaselGrotesk-Regular.svg#BaselGrotesk-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basel Grotesk';
  src: url('../../fonts/BaselGrotesk-Medium.woff2') format('woff2'),
  url('../../fonts/BaselGrotesk-Medium.woff') format('woff'),
  url('../../fonts/BaselGrotesk-Medium.ttf') format('truetype'),
  url('../../fonts/BaselGrotesk-Medium.svg#BaselGrotesk-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Beale';
  src: url('../../fonts/Beale-Black.woff2') format('woff2'),
  url('../../fonts/Beale-Black.woff') format('woff'),
  url('../../fonts/Beale-Black.ttf') format('truetype'),
  url('../../fonts/Beale-Black.svg#Beale-Black') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gopher';
  src: url('../../fonts/Gopher-Medium.woff2') format('woff2'),
  url('../../fonts/Gopher-Medium.woff') format('woff'),
  url('../../fonts/Gopher-Medium.ttf') format('truetype'),
  url('../../fonts/Gopher-Medium.svg#Gopher-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
