@import "../../../assets/styles/main";

$actionBarBackground: $stoneWhite;
$actionBarInnerBackground: $warm500;

.action-bar {
  &__container {
    background-color: $actionBarBackground;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -2rem;
    width: 80%;
    margin: 0 auto;

    @media (max-width: 640px) {
      width: 90%;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background-color: $actionBarInnerBackground;
    padding: 2rem 2rem;
    border-radius: 12px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.20);
    width: 100%;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: start;
      align-content: center;
    }
  }

  &__description {

    @media (max-width: 640px) {
      padding-bottom: 2rem;
    }

    h4 {
      margin: 0;
    }
  }
}
