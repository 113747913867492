@import "../../../assets/styles/main";

$footerBackground: $neutral100;

.footer {
  &__container {
    padding-top: 6rem;
    min-height: 334px;
    padding-bottom: 3rem;
  }

  &__logo {
    width: 203px;
    height: 48px;
    background-image: url('../../../assets/images/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 480px) {
      width: 142px;
      height: 34px;
    }
  }
}
