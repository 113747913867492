@import "../../../assets/styles/main";

.wrapper{
  width: 725px;
  height: 100vh;
  position:relative;
  margin:auto;
  overflow:hidden;

  @media (max-width: 1440px) {
    width: 595px;
  }

  @media (max-width: 1200px) {
    width: 495px;
  }

  @media (max-width: 540px) {
    margin: 0;
    width: 100%;
  }
}

.box {
  width:725px;
  height:5004px;
  position:absolute;
  background-image: url('../../../assets/images/components.png');
  background-size: contain;

  @media (max-width: 1440px) {
    width: 595px;
  }

  @media (max-width: 1200px) {
    width: 495px;
  }

  @media (max-width: 540px) {
    width: 100%;
  }
}



.boxes {
  position:relative;
  left:0;
  top: -150px;
  z-index: -1;
}
