@import "../../../assets/styles/main";

$platformBackground: $neutral100;
$platformDescription: $stone600;
$platformListItemBorder: $stone300;

.platform {

  &__container {
    width: 100%;
    min-height: 100vh;
    background-color: $platformBackground;
    display: flex;
    position: relative;
  }

  &__content {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media (max-width: 768px) {
      padding-top: 3rem;
    }


  }

  &__description {
    color: $platformDescription;
    max-width: 530px;
  }

  &__details {
    max-width: 424px;
  }

  &__list {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;
  }

  &__list-item {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    border-bottom: 1px solid $platformListItemBorder;
    display: flex;
  }

  &__item-index {
    font-weight: 500;
    display: block;
    margin-right: 0.75rem;
  }

  &__visual {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;

    @media (max-width: 480px) {
      width: 70%;
    }

    &-image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom left;
    }
  }
}
