%display {
  font-size: 6rem;
  line-height: 6rem;
  text-transform: uppercase;
  margin: 1.5rem;

  @media (max-width: 768px) {
    font-size: 4.5rem;
    line-height: 4.5rem;
    margin: .5rem;
  }

  @media (max-width: 640px) {
    font-size: 4rem;
    line-height: 4rem;
  }

  @media (max-width: 480px) {
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 380px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

}

%headingLg {
  font-size: 4.5rem;
  line-height: 5.25rem;

  @media (max-width: 1200px) {
    font-size: 3rem;
    line-height: 4.25rem;
  }

}

%headingMd {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

%headingSm {
  font-size: 1.75rem;
  line-height: 2rem;
}

%body {
  font-size: 1.125rem;
  line-height: 1.375rem;
}

%caption {
  font-size: .875rem;
  line-height: 1.125rem;
}

%baselGrotesk {
  font-family: 'Basel Grotesk', sans-serif;
}

%beale {
  font-family: 'Beale', sans-serif;
}

%gopher {
  font-family: 'Gopher', sans-serif;
}

$aNavItemColor: $neutralBlack;



h1 {
  @extend %display;
  @extend %gopher;
  font-weight: 500;
}

h2 {
  @extend %headingLg;
  @extend %beale;
  font-weight: bold;
}

h3 {
  @extend %headingMd;
  @extend %baselGrotesk;
  font-weight: 300;

  @media (max-width: 640px) {
    font-size: 1.75rem;
    line-height: 2rem;
  }

}

h4 {
  @extend %headingSm;
  @extend %baselGrotesk;
  font-weight: 300;
}

body {
  @extend %baselGrotesk;
}

p {
  @extend %body;
  @extend %baselGrotesk;

  strong {
    font-weight: 500;
  }
}

caption {
  @extend %caption;
  @extend %baselGrotesk;

  strong {
    font-weight: 500;
  }
}

a.nav-item {
  text-decoration: none;
  @extend %body;
  font-weight: 500;
  color: $aNavItemColor;
}

.no-margin {
  margin: 0;
}

