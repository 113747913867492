@import "../../../assets/styles/main";

$heroBackground: $neutral100;

.hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  background-color: $neutral100;

  @media (max-width: 540px) {
    &.zp-padding {
      padding: 0 0;
    }
  }

  @media (max-width: 1024px) {
    overflow: hidden;
  }



  &__content {
    width: 50%;

    @media (max-width: 540px) {
      width: 100%;
      height: 100%;
      z-index: 10;
      padding: 3rem;
      background-color: rgba($heroBackground, 0.88);
      backdrop-filter: blur(2px);
      border-radius: 3px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h2 {
      @media (max-width: 960px) {
        margin-bottom: 0;
      }
    }

    h4 {
      @media (max-width: 960px) {
        margin-top: 1rem;
      }
    }

  }

  &__scroll {

    @media (max-width: 540px) {
      width: 100%;
      position: absolute;
    }

    width: 50%;
    z-index: 1;
    position: relative;
  }

}

$headerDefaultBackground: $neutral100;

.hero-header {
  padding: 1.5rem 6rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  backdrop-filter: none;
  align-items: center;

  @media (max-width: 768px) {
    padding: 1.5rem 2rem;
  }
  
  @media (max-width: 540px) {
    .btn {
      display: none;
    }
  }

  &.background {
    background-color: rgba($headerDefaultBackground, 0.88);
    backdrop-filter: blur(30px);

    @media (max-width: 540px) {
      background-color: transparent;
      backdrop-filter: blur(0);
    }
  }

  &__navigation {
    list-style: none;
    display: flex;
    align-items: center;
  }

  &__nav-item {
    margin-right: 1.5rem;
    cursor: pointer;

    @media (max-width: 540px) {
        margin-right: 0;
    }
  }

  &__logo {
    width: 203px;
    height: 48px;
    background-image: url('../../../assets/images/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 480px) {
      width: 142px;
      height: 34px;
    }
  }
}
