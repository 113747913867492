@import "../../../assets/styles/main";

$textBlockAltBackgroundColor: $cool100;

.text-block-alt {

  &__container {
    min-height: 100vh;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    background-color: $textBlockAltBackgroundColor;
  }

  &__title {
    text-align: left;
    padding-top: 6rem;

    h1 {
      margin: 0;

    }
  }

  &__sub-title {

  }

  &__screen {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 22px;
    min-height: 538px;

    @media (max-width: 1024px) {
      min-height: 508px;
    }

    @media (max-width: 768px) {
      min-height: 400px;
    }

    @media (max-width: 640px) {
      min-height: 330px;
    }

    @media (max-width: 480px) {
      min-height: 240px;
    }
  }

  &__component {
    width: 375px;
    height: 595px;
    background-repeat: no-repeat;
    background-size: 375px auto;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    align-items: start;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  &__company {
    h2 {
      margin-top: 2rem;
      color: $cool500;

      @media (max-width: 640px) {
        margin: 0;
      }
    }
  }

  &__company-desc {
    width: 50%;

    @media (max-width: 640px) {
      width: 100%;
    }

    h4 {
      @media (max-width: 640px) {
        margin-top: 0;
      }
    }
  }



}
