// Colors
// For color we use reverse contrast color, if use of 500 color background use 100 color type and vice versa.
// In order to make sure we are meeting with accessibility needs our colors dont mix unless in use for illustration.
// When using a neutral colors we can utilize colors to add a sense of depth and elevation.

// Stone
$stone100: #FFFDF8;
$stone200: #F3EEE3;
$stone300: #E2DCCE;
$stone400: #D2CDC1;
$stone500: #665B55;
$stone600: #38322E;
$stoneWhite: #FFFEFC;
$stoneBlack: #141210;


// Neutral
$neutral100: #FFF9ED;
$neutral200: #FFFDF8;
$neutral300: #CECAC8;
$neutral400: #ADA4A3;
$neutral500: #665B55;
$neutral600: #38322E;
$neutralBlack: #141210;

// Warm
$warm100: #FFD7BB;
$warm200: #F0B195;
$warm300: #B3724F;
$warm400: #8C4936;
$warm500: #47241B;

// Cool
$cool100: #CCDCDD;
$cool200: #B2C1C2;
$cool300: #7E8B8C;
$cool400: #0C4551;
$cool500: #002E38;

// Green
$green100: #D4F7D8;
$green200: #B4DBBC;
$green300: #74A07D;
$green400: #0C4551;
$green500: #294930;

.green-100 {
  color: $green100;
}

.warm-100 {
  color: $warm100;
}

.stone-100 {
  color: $stone100;
}

.stone-white {
  color: $stoneWhite;
}

.stone-600 {
  color: $stone600;
}



