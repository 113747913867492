

// Cool Button Properties

$coolButtonText: $cool100;
$coolButton: $cool400;
$coolButtonHover: $cool500;

// Green Button Properties
$greenButtonText: $green100;
$greenButton: $green400;
$greenButtonHover: $green500;

// Warm Button Properties
$warmButtonText: $warm100;
$warmButton: $warm400;
$warmButtonHover: $warm500;

// Stone Button Properties
$stoneButtonText: $stoneWhite;
$stoneButton: $stoneBlack;
$stoneButtonHover: $stone600;

// Stone Dark Button Properties
$stoneDarkButtonText: $cool500;
$stoneDarkButton: $cool100;
$stoneDarkButtonHover: $cool200;

// Green Dark Button Properties
$greenDarkButtonText: $green500;
$greenDarkButton: $green100;
$greenDarkButtonHover: $green200;

// Warm Dark Button Properties
$warmDarkButtonText: $warm500;
$warmDarkButton: $warm100;
$warmDarkButtonHover: $warm200;

// Stone Dark Button Properties
$stoneDarkButtonText: $stone600;
$stoneDarkButton: $stone200;
$stoneDarkButtonHover: $stone500;

// Stone White Button Properties
$stoneWhiteButtonText: $stoneBlack;
$stoneWhiteButton: $stoneWhite;
$stoneWhiteButtonHover: $stone100;

// Ghost Button Properties
$ghostButtonText: $stone600;
$ghostButton: transparent;
$ghostButtonBorder: 2px solid $stone600;

// Button Size Properties
$buttonSmall: .75rem 1.5rem;
%buttonSmallText {
  @extend %body;
  font-weight: 500;
}

$buttonMedium: .75rem 3rem;
%buttonMediumText {
  @extend %body;
  font-weight: 600;
}

// Buttons

.btn {

  border-radius: 12px;
  appearance: none;
  border: none;
  cursor: pointer;

  &--small {
    padding: $buttonSmall;
    @extend %buttonSmallText;
  }

  &--medium {
    padding: $buttonMedium;
    @extend %buttonMediumText;
  }

  &--cool {
    background-color: $coolButton;
    color: $coolButtonText;

    &:hover {
      background-color: $coolButtonHover;
    }
  }

  &--green {
    background-color: $greenButton;
    color: $greenButtonText;

    &:hover {
      background-color: $greenButtonHover;
    }
  }

  &--warm {
    background-color: $warmButton;
    color: $warmButtonText;

    &:hover {
      background-color: $warmButtonHover;
    }
  }

  &--stone {
    background-color: $stoneButton;
    color: $stoneButtonText;

    &:hover {
      background-color: $stoneButtonHover;
    }
  }

  &--cool-dark {
    background-color: $stoneDarkButton;
    color: $stoneDarkButtonText;

    &:hover {
      background-color: $stoneDarkButtonHover;
    }
  }

  &--green-dark {
    background-color: $greenDarkButton;
    color: $greenDarkButtonText;

    &:hover {
      background-color: $greenDarkButtonHover;
    }
  }

  &--warm-dark {
    background-color: $warmDarkButton;
    color: $warmDarkButtonText;

    &:hover {
      background-color: $warmDarkButtonHover;
    }
  }

  &--stone-dark {
    background-color: $stoneDarkButton;
    color: $stoneDarkButtonText;

    &:hover {
      background-color: $stoneDarkButtonHover
    }
  }

  &--stone-white {
    background-color: $stoneWhiteButton;
    color: $stoneWhiteButtonText;

    &:hover {
      background-color: $stoneWhiteButtonHover;
    }
  }

  &--ghost {
    background-color: $ghostButton;
    color: $ghostButtonText;
    border: $ghostButtonBorder;
  }
}



